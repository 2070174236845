import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Dialog from 'components/dialog';

import useCheckoutStore, { INITIAL_CHECKOUT_STATE } from 'states/checkoutState';

import OrderSummary from '../components/checkout/OrderSummary';
import Payment from '../components/checkout/Payment';
import SponsorshipTypes from '../components/checkout/SponsorshipTypes';
import TicketTypes from '../components/checkout/TicketTypes';

import { useAuthState } from 'providers/AuthProvider/hooks';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import Login from 'features/auth/dialogs/Login';
import { formatCurrency } from 'utils/helpers';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';

type CheckoutFormProps = {
  isOpen: boolean;
  eventId: number;
  setIsOpen: () => void;
  type: 'sponsorship' | 'ticket';
};

const CheckoutForm = (props: CheckoutFormProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const { showDialog } = useDialogDispatcher();
  const [loading, setLoading] = useState(true);

  const { callService } = useCallService();
  const checkoutStore = useCheckoutStore();

  const { slug } = useParams();

  const theme = useTheme();

  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const {
    authState: { authenticated },
  } = useAuthState();

  const getEventDetails = async () => {
    if (slug) {
      setLoading(true);

      const { response } = await callService({
        resource: httpRoutes.events.getEventDetailsBySlug(slug),
      });

      if (response) {
        console.log(response.eventDetails.discountCodeRequired);
        checkoutStore.update({
          ...INITIAL_CHECKOUT_STATE,
          fees: {
            itemFee: response.fees.itemFee,
            serviceFee: response.fees.serviceFee,
            processingFee: response.fees.processingFee,
          },
          eventId: response.eventDetails.id,
          eventName: response.eventDetails.name,
          startsAt: response.eventDetails.startsAt,
          endsAt: response.eventDetails.endsAt,
          timezone: response.eventDetails.timezone,
          type: 'ticket',
          discountCodeRequired: response.eventDetails.discountCodeRequired,
        });
        setLoading(false);
      }
    }
  };

  const getEventSponsorship = async () => {
    if (slug) {
      setLoading(true);

      const { response } = await callService({
        resource: httpRoutes.events.getEventSponsorshipByNameSlug(slug),
      });

      if (response) {
        checkoutStore.update({
          ...INITIAL_CHECKOUT_STATE,
          eventId: response.eventSponsorship.event.id,
          eventName: response.eventSponsorship.event.name,
          startsAt: response.eventSponsorship.event.startsAt,
          endsAt: response.eventSponsorship.event.endsAt,
          timezone: response.eventSponsorship.event.timezone,
          type: 'sponsorship',
          discountCodeRequired:
            response.eventSponsorship.event.discountCodeRequired,
        });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (props.type === 'ticket') {
      getEventDetails();
    }

    if (props.type === 'sponsorship') {
      getEventSponsorship();
    }

    setActiveStep(0);

    return () => {
      setActiveStep(0);
    };
  }, []);

  const handleNext = () => {
    if (!authenticated) {
      showDialog({
        size: 'sm',
        content: <Login />,
      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleBack = () => {
    if (activeStep === 2 && checkoutStore.state.total === 0) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onSuccess = () => {
    setActiveStep(0);
    props.setIsOpen();
  };

  return (
    <Dialog
      setIsOpen={() => {
        props.setIsOpen();
      }}
      isOpen={props.isOpen}
      size="lg"
      content={
        <Box sx={{ width: '100%' }}>
          <Grid
            container
            flexDirection="row"
            alignItems="stretch"
            justifyContent="space-between"
            p={2}
            sx={{
              maxHeight: '70vh',
              minHeight: '70vh',
              height: '70vh',
            }}
          >
            {activeStep === 0 && (
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  overflowY: 'scroll',
                }}
              >
                {props.type === 'ticket' && (
                  <TicketTypes eventId={props.eventId} />
                )}
                {props.type === 'sponsorship' && (
                  <SponsorshipTypes eventId={props.eventId} />
                )}
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid item xs={12} sm={6}>
                <Payment
                  onSuccess={onSuccess}
                  type={props.type}
                  setSubmitting={(value: boolean) => setLoading(value)}
                />
              </Grid>
            )}
            {!matchDownMD && <OrderSummary />}
          </Grid>
        </Box>
      }
      actions={
        <Box sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
          <Typography variant="subtitle1" mr={2} mt={1}>
            Total: {formatCurrency(checkoutStore.state.total)}
          </Typography>
          <Button
            disabled={activeStep === 0 || loading}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />

          {activeStep === 0 && (
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={activeStep === 0 && !checkoutStore.state.orderValid}
            >
              Checkout
            </Button>
          )}
        </Box>
      }
    ></Dialog>
  );
};

export default CheckoutForm;
